<template>
	<aside class="course-editor-sidebar">
		<button type="button" class="new-chapter-btn" @click="$emit('addNewChapter')">New Chapter</button>
		<v-expansion-panels>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="course-editor-sidebar__group--header"
					>Course Intro</v-expansion-panel-header
				>
				<v-expansion-panel-content
					class="course-editor-sidebar__group--list"
					><span @click="openPopup(11)"
						>List</span
					></v-expansion-panel-content
				>
				<v-expansion-panel-content
					class="course-editor-sidebar__group--list"
					><span @click="openPopup(12)"
						>Text</span
					></v-expansion-panel-content
				>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-expansion-panels v-if="chapters.length !== 0">
			<CourseEditorSidebarElement
				v-for="(chapter, chapterIndex) in chapters"
				:key="chapter.id"
			>
				<template #name>
					<v-row class="mb-0 align-center">
						<v-col cols="8">
							<v-expansion-panel-header
								class="course-editor-sidebar__group--header"
							>
								{{ chapter.title }}
							</v-expansion-panel-header>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-end">
								<span>
									<v-icon
										@click="
											$emit('changeIndexOfChapter', {
												mark: -1,
												chapterId: chapter.id
											})
										"
										>mdi-chevron-up</v-icon
									>
								</span>
								<span
									><v-icon
										@click="
											$emit('changeIndexOfChapter', {
												mark: 1,
												chapterId: chapter.id
											})
										"
										>mdi-chevron-down</v-icon
									></span
								>
								<span
									><v-icon
										@click="
											$emit('removeChapter', chapter.id)
										"
										>mdi-delete</v-icon
									></span
								>
							</div>
						</v-col>
					</v-row>
				</template>
				<template #pages>
					<v-expansion-panel-content
						v-if="chapter.pages.length === 0"
						class="course-editor-sidebar__group--list"
					>
						<i>empty chapter, add first page</i>
						<span
							><v-icon
								@click="
									$emit('addNewPage', {
										chapterId: chapter.id
									})
								"
								>mdi-plus</v-icon
							></span
						>
					</v-expansion-panel-content>
					<v-expansion-panel-content
						v-else
						class="course-editor-sidebar__group--list"
						v-for="(page, pageIndex) in chapter.pages"
						:key="page.id"
					>
						<span
							:class="{
								'primary-text': activePageId === page.id
							}"
							@click="$emit('setActivePage', page.id)"
						>
							{{ Number(chapterIndex) + 1 }}.{{ pageIndex + 1 }}
							{{ page.title }}
						</span>

						<div class="course-editor-sidebar__group--actions">
							<span
								><v-icon
									@click="
										$emit('changeIndexOfPage', {
											mark: -1,
											chapterId: chapter.id,
											pageId: page.id
										})
									"
									>mdi-chevron-up</v-icon
								></span
							>
							<span
								><v-icon
									@click="
										$emit('changeIndexOfPage', {
											mark: 1,
											chapterId: chapter.id,
											pageId: page.id
										})
									"
									>mdi-chevron-down</v-icon
								></span
							>
							<span
								><v-icon @click="$emit('removePage', page.id)"
									>mdi-delete</v-icon
								></span
							>
							<span
								><v-icon
									@click="
										$emit('addNewPage', {
											chapterId: chapter.id
										})
									"
									>mdi-plus</v-icon
								></span
							>
						</div>
					</v-expansion-panel-content></template
				>
			</CourseEditorSidebarElement>
		</v-expansion-panels>
		<div v-else>
			<p>Empty course</p>
		</div>
	</aside>
</template>
<script>
import CourseEditorSidebarElement from "@/views/admin//course-editor/CourseEditorSidebarElement.vue";
import { widgets } from "@/util/widgets/widgets";

export default {
	components: {
		CourseEditorSidebarElement
	},
	name: "CourseEditor",
	props: {
		chapters: {
			type: Array,
			require: true
		},
		activePage: {
			type: Object,
			require: true
		},
		introBlocks: {
			type: Array,
			require: true
		}
	},
	computed: {
		activePageId() {
			return this.activePage?.id;
		}
	},
	data() {
		return {};
	},
	methods: {
		openPopup(id) {
			const widget = widgets.find(el => el.id === id);
			const editedBlock = this.introBlocks.find(
				el => el.blk_type_id === widget.blk_type
			);
			if (editedBlock) {
				this.$emit("editBlock", editedBlock);
			}
			this.$emit("openPopup", true);
			this.$emit("selectWidget", {
				...widget,
				component: widget.attribute
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
	font-weight: 400;
	line-height: 22px;
}

.mdi-delete {
	font-size: 19px;
}

.new-chapter-btn {
	display: block;
	text-align: center;
	font-weight: 600;
	font-size: 16px;
    width: 100%;
    border: 1px solid #2F91AE;
    padding: 5px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
	cursor: pointer;
}
</style>

