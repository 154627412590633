var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"course-editor-sidebar"},[_c('button',{staticClass:"new-chapter-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('addNewChapter')}}},[_vm._v("New Chapter")]),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"course-editor-sidebar__group--header"},[_vm._v("Course Intro")]),_c('v-expansion-panel-content',{staticClass:"course-editor-sidebar__group--list"},[_c('span',{on:{"click":function($event){return _vm.openPopup(11)}}},[_vm._v("List")])]),_c('v-expansion-panel-content',{staticClass:"course-editor-sidebar__group--list"},[_c('span',{on:{"click":function($event){return _vm.openPopup(12)}}},[_vm._v("Text")])])],1)],1),(_vm.chapters.length !== 0)?_c('v-expansion-panels',_vm._l((_vm.chapters),function(chapter,chapterIndex){return _c('CourseEditorSidebarElement',{key:chapter.id,scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('v-row',{staticClass:"mb-0 align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-expansion-panel-header',{staticClass:"course-editor-sidebar__group--header"},[_vm._v(" "+_vm._s(chapter.title)+" ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndexOfChapter', {
											mark: -1,
											chapterId: chapter.id
										})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndexOfChapter', {
											mark: 1,
											chapterId: chapter.id
										})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('removeChapter', chapter.id)}}},[_vm._v("mdi-delete")])],1)])])],1)]},proxy:true},{key:"pages",fn:function(){return [(chapter.pages.length === 0)?_c('v-expansion-panel-content',{staticClass:"course-editor-sidebar__group--list"},[_c('i',[_vm._v("empty chapter, add first page")]),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('addNewPage', {
									chapterId: chapter.id
								})}}},[_vm._v("mdi-plus")])],1)]):_vm._l((chapter.pages),function(page,pageIndex){return _c('v-expansion-panel-content',{key:page.id,staticClass:"course-editor-sidebar__group--list"},[_c('span',{class:{
							'primary-text': _vm.activePageId === page.id
						},on:{"click":function($event){return _vm.$emit('setActivePage', page.id)}}},[_vm._v(" "+_vm._s(Number(chapterIndex) + 1)+"."+_vm._s(pageIndex + 1)+" "+_vm._s(page.title)+" ")]),_c('div',{staticClass:"course-editor-sidebar__group--actions"},[_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndexOfPage', {
										mark: -1,
										chapterId: chapter.id,
										pageId: page.id
									})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndexOfPage', {
										mark: 1,
										chapterId: chapter.id,
										pageId: page.id
									})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('removePage', page.id)}}},[_vm._v("mdi-delete")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('addNewPage', {
										chapterId: chapter.id
									})}}},[_vm._v("mdi-plus")])],1)])])})]},proxy:true}],null,true)})}),1):_c('div',[_c('p',[_vm._v("Empty course")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }